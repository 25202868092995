import { useStaticQuery, Link, graphql } from "gatsby"
import React, { useRef, useEffect, useLayoutEffect, useState } from "react"

import styles from "./ticker.module.scss"
import Arrow from "../images/arrow-black.svg"
import { useMedia, usePrevious } from "../hooks"

const Ticker = () => {
  let pageData = usePageData()
  let projects = pageData.home[0].projectTicker
  let workflowInnovationUri = pageData.workflowInnovation[0].uri
  const contactUri = pageData.contact.uri
  let [index, next] = useStepper(projects.length)
  let previousIndex = usePrevious(index)

  let media = useMedia()
  let desktop = media(">tablet")

  let projectItems = useRef(new Map())

  let animating = useRef(false)

  // Resize the text container to fit the tallest item. This is done because
  // the items are `position: absolute` so they can stack during the
  // transition.
  let textContainerRef = useRef()
  let tallestText = useRef(0)
  tallestText.current = 0

  function projectItemRef(el, i) {
    projectItems.current.set(i, el)
  }
  function textRef(el) {
    if (el !== null) {
      tallestText.current = Math.max(
        tallestText.current,
        el.getBoundingClientRect().height
      )
    }
  }

  // Perform the text container
  useEffect(() => {
    if (textContainerRef.current !== null) {
      textContainerRef.current.style.height = tallestText.current + 96 + "px"
    }
  }, [])

  useLayoutEffect(() => {
    let animationFrameId = null
    let timeoutId = null
    if (index !== previousIndex) {
      let previousEl = projectItems.current.get(previousIndex)
      let currentEl = projectItems.current.get(index)
      if (currentEl == null) {
        console.warn("currentEl null")
        return
      }
      // First render, no previous element. Set initial opacities.
      if (previousEl == null) {
        projectItems.current.forEach((el, id) => {
          el.style.opacity = id === index ? 1 : 0
          el.style.userSelect = "none"
        })
        return
      }

      animating.current = true

      // INVERT
      previousEl.style.opacity = 1
      previousEl.style.transition = ""
      previousEl.style.zIndex = "8"

      currentEl.style.opacity = 0
      currentEl.style.zIndex = "9"
      currentEl.style.transform = "translateX(10px)"

      // PLAY
      animationFrameId = requestAnimationFrame(() => {
        previousEl.style.transition =
          "opacity .4s ease-out, transform .8s ease-out"
        previousEl.style.opacity = 0
        previousEl.style.transform = "translateX(-10px)"
        currentEl.style.transition =
          "opacity .7s ease-out, transform .7s ease-out"
        currentEl.style.opacity = 1
        currentEl.style.transform = ""
        timeoutId = setTimeout(() => {
          currentEl.style.clipPath = ""
          currentEl.style.webkitClipPath = ""
          currentEl.style.transition = ""
          previousEl.style.transition = ""
          previousEl.style.transform = ""
          animating.current = false
        }, 800)
      })
    }

    return () => {
      cancelAnimationFrame(animationFrameId)
      clearTimeout(timeoutId)
    }
  }, [index, previousIndex])

  let counter = <Counter current={index + 1} count={projects.length} />

  let howdYouDoThat = (
    // FIXME: i couldn't use the class .button--block-on-mobile because it
    // wasn't switching at the same point as the useMedia hook...
    <Link
      to={`/${contactUri}`}
      className={`button ${!desktop ? "button--block" : ""}`}
    >
      Interested? let’s talk
    </Link>
  )

  let nextButton = (
    <button
      className={`${styles.next} body-large-after-tablet`}
      onClick={event => {
        if (animating.current) return
        next()
      }}
    >
      <span style={{ marginRight: "1rem" }}> Next</span>
      <Arrow />
    </button>
  )

  return (
    <div className={styles.module}>
      <div className="wrapper">
        <div className={`mb-6 ${styles.topline}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className={`${styles.heading} body-large-after-tablet`}>
              How we partner
            </span>
            <svg height="2px" width="40px" viewBox="0 0 40 2">
              <line
                x1="0px"
                y1="1px"
                x2="40px"
                y2="1px"
                stroke="#1A1C2C"
                strokeWidth="2"
              />
            </svg>
          </div>
          {desktop ? counter : null}
        </div>
        <div className={styles.tickerProjects}>
          <div
            ref={textContainerRef}
            style={{
              position: "relative",
              // overflow: "hidden",
            }}
          >
            {projects.map((p, i) => {
              return (
                <div
                  key={p.serif}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    opacity: 0,
                    // background: "#1a1a1a",
                    transform: "translateZ(0)",
                  }}
                  ref={el => {
                    projectItemRef(el, i)
                  }}
                >
                  <h2 className={`h-1 ${styles.textContainer}`} ref={textRef}>
                    <span className="h-1 h--serif-italic">{p.serif} </span>
                    {p.sansSerif}
                  </h2>
                </div>
              )
            })}
          </div>
        </div>

        {desktop ? (
          <div
            style={{
              position: "relative",
              top: "-45px",
              zIndex: "100",
            }}
          >
            <div
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {howdYouDoThat}
              {nextButton}
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem",
              }}
            >
              {counter}
              {nextButton}
            </div>
            {howdYouDoThat}
          </>
        )}
      </div>
    </div>
  )
}

function usePageData() {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          workflowInnovation: entries(id: 16171) {
            uri
          }
          home: entries(section: "home") {
            id
            ... on Craft_home_home_Entry {
              projectTicker {
                sansSerif
                serif
              }
            }
          }
          contact: entry(section: "contact") {
            uri
          }
        }
      }
    `
  )

  // return data.craft.entries[0].projectTicker
  return data.craft
}

function useStepper(count, initial = 0) {
  let [index, setIndex] = useState(initial)
  return [index % count, () => setIndex(x => x + 1)]
}

// TODO: animation
function Counter({ current, count }) {
  // let oneLess = ((count + current - 2) % count) + 1
  return (
    <div
      style={{ fontVariantNumeric: "tabular-nums" }}
      className="body-large-after-tablet"
    >
      <span key={current} style={{ marginRight: "0.5rem" }}>
        {current}
      </span>
      {"of"}
      <span style={{ marginLeft: "0.5rem" }}>{count}</span>
    </div>
  )
}

export default Ticker

import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import cirlce from "../images/latest-news.gif"
import styles from "./latestNews.module.scss"

function SectionLink({ link }) {
  const attrs = {
    target: link.target,
    className: `link ${styles.bodyLink}`,
  }

  if (link.type === "entry") {
    return (
      <Link {...attrs} to={`/${link.element.uri}`}>
        {link.text}
      </Link>
    )
  } else if (link.type === "custom" || link.type === "url") {
    return (
      <a href={link.url} {...attrs}>
        {link.text}
      </a>
    )
  } else {
    return null
  }
}

export default function ClientLogos() {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          home: entry(section: "home") {
            ... on Craft_home_home_Entry {
              latestNews {
                ... on Craft_latestNews_BlockType {
                  sectionTitle
                  body
                  sectionLink {
                    target
                    element {
                      uri
                    }
                    url
                    text
                    type
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const home = data.craft.home
  const news = home.latestNews
  if (!news.length) {
    return null
  }
  return (
    <div className={styles.module}>
      <div className="wrapper">
        <div className={styles.moduleInner}>
          <div className={styles.title}>
            <img
              className={styles.image}
              src={cirlce}
              alt=""
              role="presentation"
            />
            <div className={styles.titleText}>
              <h2>{news[0].sectionTitle}</h2>
            </div>
          </div>
          <div className={styles.body}>
            <p>
              {news[0].body}{" "}
              {news.length && <SectionLink link={news[0].sectionLink} />}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"

import style from "./intro.module.scss"
import Water from "./water"

const Intro = () => {
  let [hoveredSentence, setHoveredSentence] = React.useState(1)

  React.useEffect(() => {
    setHoveredSentence(1)
    let timeoutId = setTimeout(() => {
      setHoveredSentence(2)
      timeoutId = setTimeout(() => {
        setHoveredSentence(3)
      }, 5000)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .5s ease-in-out",
      opacity: hoveredSentence >= index ? 1 : 0,
    },
  })

  const [lineDisplayed, setLineDisplayed] = React.useState(0)
  const [fade, setFade] = React.useState(1)

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  React.useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setFade(0)
      timeout(700).then(r => {
        setLineDisplayed((lineDisplayed + 1) % 2)
        setFade(1)
      })
    }, 5000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [lineDisplayed])

  let transition = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: index ? 1 : 0,
    },
  })

  let introLines = [
    <p>
      <span className="h--serif-italic" {...group(2)}>
        Shifts in
      </span>{" "}
      <span className="underline" {...group(2)}>
        culture and technology
      </span>{" "}
      <span className="h--serif-italic" {...group(2)}>
        are creating opportunities to
      </span>{" "}
      <span {...group(2)}>bring people and brands closer together</span>{" "}
      <span className="h--serif-italic" {...group(2)}>
        than ever before.
      </span>{" "}
    </p>,
    <p>
      <span {...group(2)}>We help our clients</span>{" "}
      <span className="h--serif" {...group(2)}>
        embrace these shifts
      </span>{" "}
      <span className="h--serif-italic" {...group(2)}>
        by leading with
      </span>{" "}
      <span className="underline" {...group(2)}>
        emotion, imagination and humanity.
      </span>
    </p>,
  ]

  return (
    <div className={style.intro}>
      <Water className={style.videoCanvas} stage={hoveredSentence} />
      <div className={style.introText}>
        <div className="wrapper">
          <div className="columns is-desktop">
            <div className="column is-11-desktop">
              <div className="h-display" {...transition(fade)}>
                {introLines[lineDisplayed]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro

import { useStaticQuery, Link, graphql } from "gatsby"
import React, { useRef } from "react"
import { animated } from "react-spring"
import Imgix from "react-imgix"
// Mobile carousel
import TinySlider from "tiny-slider-react"

import { useAutoCarousel } from "../hooks"
import useItemsLayout, { sizes } from "../hooks/useCarouselItemLayout"
import styles from "./carousel.module.scss"
import VideoInline from "./videoInline"

const Carousel = ({ showTitle = true }) => {
  let pages = usePages()

  let containerRef = useRef(null)
  let { columnPx, gutterPx, offsets, mode } = useItemsLayout(
    containerRef,
    pages.map(p => p.aspectRatio)
  )

  let carouselRef = React.useRef(null)
  let offset = useAutoCarousel(carouselRef, pages.length)

  const mobileCarouselRef = useRef(null)
  const updateActiveMobileSlide = info => {
    const indexPrev = info.indexCached
    const indexCurrent = info.index
    info.slideItems[indexPrev].classList.remove("is-active")
    info.slideItems[indexCurrent].classList.add("is-active")
  }

  return (
    <div className={styles.module}>
      {showTitle && (
        <div className="wrapper">
          <div className="mb-6">
            <h2 className="h-1">
              <span className="h-1 h--serif-italic">Some of our </span>
              recent work:
            </h2>
          </div>
        </div>
      )}
      <div className={styles.mobileCarousel}>
        {TinySlider.length && (
          <TinySlider
            ref={mobileCarouselRef}
            settings={{
              mouseDrag: true,
              controls: false,
              nav: false,
              gutter: 12,
              center: true,
              fixedWidth: 300,
              arrowKeys: true,
              autoplay: true,
              swipeAngle: 25,
              preventScrollOnTouch: "auto",
              autoplayTimeout: 3000,
              speed: 500,
              loop: true,
              autoplayButtonOutput: false,
              responsive: {
                500: {
                  fixedWidth: 400,
                  gutter: 24,
                },
                768: {
                  disable: true,
                },
              },
            }}
            startIndex={0}
            onInit={() => {
              const info = mobileCarouselRef.current.slider.getInfo()
              updateActiveMobileSlide(info)
            }}
            onIndexChanged={updateActiveMobileSlide}
          >
            {pages.map((item, i) => {
              let image = item.coverImages[0].ratio_1x1[0]
              return (
                <div key={`tablet-carousel-${i}`}>
                  <Link
                    key={`mobile-slide-${i}`}
                    className="img-tile"
                    to={`/${item.uri}`}
                    draggable={false}
                  >
                    <div className={styles.mobileCarouselItemImage}>
                      <div className="img-tile-image">
                        <Imgix
                          width={image.width}
                          height={image.height}
                          src={image.url}
                          htmlAttributes={{
                            alt: "",
                            draggable: false,
                          }}
                        />
                      </div>
                    </div>
                    <div className={`${styles.itemCaption}`}>
                      <h3 className={`subhead mb-1 ${styles.itemSubhead}`}>
                        {item.title}
                      </h3>
                      {item.subtitle && (
                        <p className={styles.itemTitle}>
                          <span>{item.subtitle}</span>
                        </p>
                      )}
                    </div>
                  </Link>
                </div>
              )
            })}
          </TinySlider>
        )}
      </div>
      <div ref={carouselRef} className={styles.carousel}>
        <ul ref={containerRef} className={`wrapper ${styles.carouselWrapper}`}>
          {pages.map((item, i) => {
            let size = sizes[mode][item.aspectRatio]
            let width = columnPx * size.columns + (size.columns - 1) * gutterPx
            let height = width * (size.aspect[1] / size.aspect[0])

            let src = item.coverImages[0][item.aspectRatio][0].url
            let isVideo = src.includes(".mp4")

            // let windowWidth = window.innerWidth
            let allPagesWidth = offsets[offsets.length - 1]
            let content = (
              <Link className="img-tile" to={`/${item.uri}`} draggable={false}>
                <div
                  className={styles.itemImage}
                  style={{
                    width,
                    height,
                  }}
                >
                  <div className="img-tile-image">
                    <Imgix
                      width={width}
                      height={height}
                      src={src}
                      htmlAttributes={{
                        alt: "",
                        draggable: false,
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.itemCaption}`}>
                  <h3 className={`subhead mb-1 ${styles.itemSubhead}`}>
                    {item.title}
                  </h3>
                  {item.subtitle && (
                    <p className={styles.itemTitle}>
                      <span>{item.subtitle}</span>
                    </p>
                  )}
                </div>
              </Link>
            )

            if (isVideo) {
              const attrs = {
                src: src,
                type: "video/mp4",
                poster: null,
              }

              content = (
                <Link
                  className="img-tile"
                  to={`/${item.uri}`}
                  draggable={false}
                >
                  <div
                    className={styles.itemImage}
                    style={{
                      width,
                      height,
                    }}
                  >
                    <div className="img-tile-image">
                      <VideoInline {...attrs} />
                    </div>
                  </div>
                  <div className={`${styles.itemCaption}`}>
                    <h3 className={`subhead mb-1 ${styles.itemSubhead}`}>
                      {item.title}
                    </h3>
                    {item.subtitle && (
                      <p className={styles.itemTitle}>
                        <span>{item.subtitle}</span>
                      </p>
                    )}
                  </div>
                </Link>
              )
            }

            let commonProps = {
              className: `${styles.slide} ${
                item.comingSoon ? styles.comingSoon : ""
              }`,
            }

            return (
              <React.Fragment key={`project-item-${i}`}>
                <animated.li
                  key={item.id + "-copy-left"}
                  {...commonProps}
                  style={{
                    width: width,
                    transform: offset.interpolate(v => {
                      let left = offsets[i]
                      let offs = v % allPagesWidth
                      let x = offs + left - allPagesWidth
                      return `translate3d(${x}px, 0px, 0px) scale(1)`
                    }),
                  }}
                >
                  {content}
                </animated.li>
                <animated.li
                  key={item.id}
                  {...commonProps}
                  style={{
                    width: width,
                    transform: offset.interpolate(v => {
                      let left = offsets[i]
                      let offs = v % allPagesWidth
                      let x = offs + left
                      return `translate3d(${x}px, 0px, 0px) scale(1)`
                    }),
                  }}
                >
                  {content}
                </animated.li>
                <animated.li
                  key={item.id + "-copy-right"}
                  {...commonProps}
                  style={{
                    width: width,
                    transform: offset.interpolate(v => {
                      let left = offsets[i]
                      let offs = v % allPagesWidth
                      let x = offs + left + allPagesWidth
                      return `translate3d(${x}px, 0px, 0px) scale(1)`
                    }),
                  }}
                >
                  {content}
                </animated.li>
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

function usePages() {
  const data = useStaticQuery(
    graphql`
      query Carousel {
        craft {
          entries(section: "home") {
            ... on Craft_home_home_Entry {
              carousel {
                ... on Craft_carousel_BlockType {
                  aspectRatio
                  carouselPage {
                    ... on Craft_work_work_Entry {
                      id
                      title
                      subtitle
                      uri
                      coverImages {
                        ... on Craft_coverImages_BlockType {
                          ratio_1x1 {
                            url
                            width
                            height
                          }
                          ratio_4x3 {
                            url
                            width
                            height
                          }
                          ratio_3x4 {
                            url
                            width
                            height
                          }
                          ratio_16x9 {
                            url
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return data.craft.entries[0].carousel
    .filter(item => item.carouselPage[0] != null)
    .map(p => {
      let page = p.carouselPage[0]
      return {
        ...page,
        aspectRatio: p.aspectRatio,
      }
    })
}

export default Carousel

import React from "react"
import { graphql } from "gatsby"

import Intro from "../components/intro"
import ClientLogos from "../components/clientLogos"
import CustomFeature from "../components/customFeature"
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import Ticker from "../components/ticker"
import HeadingCta from "../components/headingCta"
import LatestNews from "../components/latestNews"

export const query = graphql`
  query {
    craft {
      entry(section: "home") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_home_home_Entry {
          headingCta {
            ...HeadingCta
          }
        }
      }
      allAthenaIssues: categories(group: "athenaIssues") {
        ...AllIssues
      }
      athenaWithFeature: entry(section: "athena") {
        uri
        ... on Craft_athena_athena_Entry {
          featuredIssue {
            ...AthenaFeature
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const entry = data.craft.entry
  const seo = entry.seomatic
  const headingCta = entry.headingCta[0]
  // Athena Feature
  const athenaWithFeature = data.craft.athenaWithFeature
  const allIssues = data.craft.allAthenaIssues

  return (
    <Layout seo={seo}>
      <h1 className="screen-reader-only">{entry.title}</h1>
      <Intro />
      <LatestNews />
      <Carousel />
      <Ticker />
      <CustomFeature />
      <ClientLogos />
      <HeadingCta data={headingCta} />
    </Layout>
  )
}

export default IndexPage

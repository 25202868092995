import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import widont from "widont"
import Imgix, { Picture, Source, buildURL } from "react-imgix"
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import styles from "./customFeature.module.scss"

function SectionLink({ link }) {
  const attrs = {
    target: link.target,
    className: `link ${styles.bodyLink}`,
  }

  if (link.type === "entry") {
    return (
      <Link {...attrs} to={`/${link.element.uri}`}>
        {link.text}
      </Link>
    )
  } else if (link.type === "custom" || link.type === "url") {
    return (
      <a href={link.url} {...attrs}>
        {link.text}
      </a>
    )
  } else {
    return null
  }
}

const CustomFeature = props => {
  const data = useStaticQuery(
    graphql`
      query CustomFeature {
        craft {
          home: entry(section: "home") {
            id
            ... on Craft_home_home_Entry {
              id
              customFeature {
                ... on Craft_customFeature_BlockType {
                  id
                  customFeatureTitle
                  customFeatureUrl {
                    target
                    element {
                      uri
                    }
                    url
                    text
                    type
                  }
                  subtitle
                  image {
                    ... on Craft_zjContent_Asset {
                      id
                      altText
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const substack = data.craft.home.customFeature[0]
  const title = substack.customFeatureTitle
  const subtitle = substack.subtitle
  const thumbnail1x1 = substack.image[0]
  // This returns a two-digit number of the issue order based on how the categories are order in Craft

  return (
    <div className={styles.module}>
      <div className="wrapper">
        <div className={styles.feature}>
          <div className={styles.featureImage}>
            <Picture>
              <Source
                src={thumbnail1x1.url}
                className="lazyload"
                sizes="(min-width: 1408px) 447px, (min-width: 1024px) calc(40vw - 5rem), calc(100vw - 5rem)"
                htmlAttributes={{
                  media: "(min-width: 1024px)",
                  alt: thumbnail1x1.altText,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
              />
              <Imgix
                className="lazyload"
                src={thumbnail1x1.url}
                sizes="(min-width: 1408px) 447px, (min-width: 1024px) calc(40vw - 5rem), calc(100vw - 5rem)"
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                htmlAttributes={{
                  src: buildURL(thumbnail1x1.url, { w: 50, blur: 40 }),
                  alt: thumbnail1x1.altText,
                }}
              />
            </Picture>
          </div>
          <div className={styles.featureText}>
            <div className="mb-3">
              <p
                className="h-3 h--cms-title"
                dangerouslySetInnerHTML={{
                  __html: widont(subtitle),
                }}
              />
            </div>
            <div>
              <SectionLink link={substack.customFeatureUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomFeature
